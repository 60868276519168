import React, { createContext, useState, useContext } from 'react';

const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const startLoading = (message = '') => {
    setLoading(true);
    setLoadingMessage(message);
  };

  const stopLoading = () => {
    setLoading(false);
    setLoadingMessage('');
  };

  const socialMediaOperation = async (operation, message) => {
    startLoading(message);
    try {
      await operation();
    } finally {
      stopLoading();
    }
  };

  return (
    <LoadingContext.Provider value={{ loading, loadingMessage, setLoading, startLoading, stopLoading, socialMediaOperation }}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
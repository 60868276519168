import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence, signInWithCustomToken, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

class FirebaseSingleton {
  constructor() {
    this.app = null;
    this.auth = null;
    this.firestore = null;
    this.initializationPromise = null;
  }

  // Initialize Firebase with the provided config
  async initialize(firebaseConfig) {
    if (this.app) {
      console.log('[FirebaseSingleton] Firebase already initialized');
      return this.app;
    }

    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = (async () => {
      try {
        console.log('[FirebaseSingleton] Starting Firebase initialization...');
        this.app = initializeApp(firebaseConfig);
        console.log('[FirebaseSingleton] Firebase app initialized');

        this.auth = getAuth(this.app);
        console.log('[FirebaseSingleton] Firebase Auth instance created');

        await setPersistence(this.auth, browserLocalPersistence);
        console.log('[FirebaseSingleton] Firebase Auth persistence set to browserLocalPersistence');

        this.firestore = getFirestore(this.app);
        console.log('[FirebaseSingleton] Firestore instance created');

        console.log('[FirebaseSingleton] Firebase initialized successfully');
        return this.app;
      } catch (error) {
        console.error('[FirebaseSingleton] Error initializing Firebase:', error);
        this.resetInitialization();
        throw error;
      }
    })();

    return this.initializationPromise;
  }

  // Reset Firebase initialization (useful for retrying)
  resetInitialization() {
    console.log('[FirebaseSingleton] Resetting Firebase initialization');
    this.app = null;
    this.auth = null;
    this.firestore = null;
    this.initializationPromise = null;
  }

  // Check if Firebase is already initialized
  isInitialized() {
    return !!(this.app && this.firestore);
  }

  // Get Firebase App instance
  getApp() {
    if (!this.app) {
      throw new Error('[FirebaseSingleton] Firebase app not initialized. Call initialize() first.');
    }
    return this.app;
  }

  // Get Firebase Auth instance
  getAuthInstance() {
    if (!this.auth) {
      throw new Error('[FirebaseSingleton] Firebase Auth not initialized. Call initialize() first.');
    }
    return this.auth;
  }

  // Get Firestore instance
  getFirestoreInstance() {
    if (!this.firestore) {
      throw new Error('[FirebaseSingleton] Firestore not initialized. Call initialize() first.');
    }
    return this.firestore;
  }

  // Sign in user with a custom token
  async signInWithCustomToken(token) {
    try {
      console.log('[FirebaseSingleton] Attempting to sign in with custom token');
      const auth = this.getAuthInstance();
      const userCredential = await signInWithCustomToken(auth, token);
      console.log('[FirebaseSingleton] Successfully signed in with custom token');
      return userCredential;
    } catch (error) {
      console.error('[FirebaseSingleton] Error signing in with custom token:', error);
      throw error;
    }
  }

  // Sign out user
  async signOut() {
    try {
      console.log('[FirebaseSingleton] Attempting to sign out');
      const auth = this.getAuthInstance();
      await signOut(auth);
      console.log('[FirebaseSingleton] User signed out successfully');
    } catch (error) {
      console.error('[FirebaseSingleton] Error signing out:', error);
      throw error;
    }
  }

  // Monitor Firebase authentication state changes
  onAuthStateChanged(callback) {
    try {
      console.log('[FirebaseSingleton] Setting up auth state change listener');
      const auth = this.getAuthInstance();
      return onAuthStateChanged(auth, (user) => {
        console.log(`[FirebaseSingleton] Auth state changed: ${user ? 'User signed in' : 'User signed out'}`);
        callback(user);
      });
    } catch (error) {
      console.error('[FirebaseSingleton] Error setting up auth state change listener:', error);
      throw error;
    }
  }

  // Get the currently signed-in user
  async getCurrentUser() {
    try {
      console.log('[FirebaseSingleton] Getting current user');
      const auth = this.getAuthInstance();
      return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          unsubscribe();
          resolve(user);
        }, reject);
      });
    } catch (error) {
      console.error('[FirebaseSingleton] Error getting current user:', error);
      throw error;
    }
  }
}

const firebaseInstance = new FirebaseSingleton();
export default firebaseInstance;

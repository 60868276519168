import React, { createContext, useState, useContext, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import firebaseInstance from '../scripts/firebaseSingleton';
import { useUser } from './UserContext';
import { useLoading } from './LoadingContext';

const GameStatsContext = createContext();

export const useGameStats = () => useContext(GameStatsContext);

export const GameStatsProvider = ({ children }) => {
  const { user } = useUser();
  const { setLoading } = useLoading();
  const [gameStats, setGameStats] = useState({
    gamesPlayed: 0,
    highestScore: 0,
    lastGameScore: {
      score: 0,
      timestamp: null,
      totalScore: 0,
    },
    level: 1,
    totalCoinsCollected: 0,
    totalScore: 0,
    totalTimePlayed: 0,
    xp: 0,
  });

  useEffect(() => {
    if (user && !user.isGuest && firebaseInstance.isInitialized()) {
      loadGameStats();
    }
  }, [user]);

  const loadGameStats = async () => {
    if (!user || user.isGuest) return;

    setLoading(true);
    try {
      const db = firebaseInstance.getFirestoreInstance();
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const loadedGameStats = userData.gameStats || gameStats;
        setGameStats(loadedGameStats);
        console.log('[GameStatsContext] Loaded game stats:', loadedGameStats);
      } else {
        console.log('[GameStatsContext] No user document found. Creating new game stats.');
        await setDoc(userDocRef, { gameStats });
      }
    } catch (error) {
      console.error('[GameStatsContext] Error loading game stats:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateGameStats = async (newStats) => {
    if (user && user.isGuest) {
      console.log('[GameStatsContext] Guest user, not updating stats');
      return;
    }

    const updatedStats = { ...gameStats, ...newStats };
    console.log('[GameStatsContext] Updating game stats:', updatedStats);
    setGameStats(updatedStats);

    if (user && !user.isGuest) {
      setLoading(true);
      try {
        const db = firebaseInstance.getFirestoreInstance();
        const userDocRef = doc(db, 'users', user.uid);
        await setDoc(userDocRef, { gameStats: updatedStats }, { merge: true });
        console.log('[GameStatsContext] Game stats updated in Firestore');
      } catch (error) {
        console.error('[GameStatsContext] Error updating game stats:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <GameStatsContext.Provider value={{ gameStats, updateGameStats, loadGameStats }}>
      {children}
    </GameStatsContext.Provider>
  );
};

export default GameStatsProvider;

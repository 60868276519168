import React, { createContext, useState, useContext } from 'react';

const GuestContext = createContext();

export const useGuest = () => useContext(GuestContext);

export const GuestProvider = ({ children }) => {
  const [isGuest, setIsGuest] = useState(false);

  const setGuestMode = () => setIsGuest(true);

  return (
    <GuestContext.Provider value={{ isGuest, setGuestMode }}>
      {children}
    </GuestContext.Provider>
  );
};

// src/components/LoadingScreen.jsx

import React, { useState, useEffect, useCallback } from 'react';

// Updated Loading Phrases Relevant to FireBoiler
const loadingPhrases = {
  default: [
    'Initializing Firebase services...',
    'Setting up TypeScript environment...',
    'Loading project configurations...',
    'Connecting to the database...',
    'Preparing your development workspace...',
  ],
  missions: [
    'Deploying authentication modules...',
    'Configuring API integrations...',
    'Setting up real-time database...',
    'Initializing cloud functions...',
    'Optimizing security settings...',
  ],
  telegramConnect: [
    'Connecting to Telegram integrations...',
    'Syncing project updates via Telegram...',
    'Configuring Telegram bots...',
    'Establishing secure connections...',
    'Loading Telegram communication channels...',
  ],
  guest: [
    'Setting up guest access...',
    'Configuring temporary user session...',
    'Loading limited access features...',
    'Initializing guest development environment...',
    'Preparing guest-specific configurations...',
  ],
};

// Updated Loading States Relevant to FireBoiler
const loadingStates = {
  default: [
    'Initializing Firebase...',
    'Authenticating user...',
    'Loading project data...',
    'Preparing development tools...',
    'Setting up the project environment...',
  ],
  guest: [
    'Configuring guest account...',
    'Loading guest access features...',
    'Setting up temporary environment...',
    'Preparing guest development tools...',
    'Initializing guest session...',
  ],
};

const LOADING_TIMEOUT = 30000; // 30 seconds

const LoadingScreen = ({
  type = 'default',
  error = null,
  customMessage = '',
  onTimeout = () => {},
  isGuest = false,
}) => {
  const [phrase, setPhrase] = useState('');
  const [progress, setProgress] = useState(0);
  const [currentState, setCurrentState] = useState(0);

  const updatePhrase = useCallback(() => {
    const phrases = loadingPhrases[isGuest ? 'guest' : type] || loadingPhrases.default;
    setPhrase(phrases[Math.floor(Math.random() * phrases.length)]);
  }, [type, isGuest]);

  const updateLoadingState = useCallback(() => {
    const states = isGuest ? loadingStates.guest : loadingStates.default;
    setCurrentState((prevState) => (prevState < states.length - 1 ? prevState + 1 : prevState));
  }, [isGuest]);

  useEffect(() => {
    updatePhrase();
    const phraseInterval = setInterval(updatePhrase, 5000);
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + Math.random() * 3;
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 200);
    const stateInterval = setInterval(updateLoadingState, 3000);

    const timeoutId = setTimeout(() => {
      onTimeout();
    }, LOADING_TIMEOUT);

    return () => {
      clearInterval(phraseInterval);
      clearInterval(progressInterval);
      clearInterval(stateInterval);
      clearTimeout(timeoutId);
    };
  }, [type, isGuest, customMessage, updatePhrase, updateLoadingState, onTimeout]);

  if (error) {
    return (
      <div className="loading-screen error">
        <div className="loading-content">
          <h2>Error</h2>
          <p>{error}</p>
          <p>Please refresh the page and try again.</p>
        </div>
      </div>
    );
  }

  const currentLoadingStates = isGuest ? loadingStates.guest : loadingStates.default;

  return (
    <div className="loading-screen flex items-center justify-center min-h-screen bg-gray-100">
      <div className="loading-content text-center p-8 bg-white rounded-lg shadow-md">
        <div className="spinner mb-6"></div>
        <h2 className="text-2xl font-semibold mb-2">
          {progress >= 100 ? 'Almost there...' : 'Loading...'}
        </h2>
        <p className="loading-phrase text-gray-600 mb-4">{phrase}</p>
        <p className="loading-state text-gray-500 mb-6">
          {customMessage || (progress >= 100 ? 'Finalizing setup...' : currentLoadingStates[currentState])}
        </p>
        <div className="progress-bar w-full bg-gray-200 rounded-full h-4 mb-6">
          <div className="progress bg-green-500 h-4 rounded-full transition-width duration-300" style={{ width: `${progress}%` }}></div>
        </div>
        <p className="loading-footer text-gray-500">
          {isGuest
            ? 'Please wait while we set up your guest development environment...'
            : 'Preparing your FireBoiler project...'}
        </p>
      </div>
    </div>
  );
};

export default LoadingScreen;

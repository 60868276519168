// UserContext.jsx
import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGuest } from './GuestContext';
import { useLoading } from './LoadingContext';
import firebaseInstance from '../scripts/firebaseSingleton';
import { onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth';
import { setUpGuestMode } from '../scripts/auth';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const { setGuestMode } = useGuest();
  const { setLoading } = useLoading();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = firebaseInstance.getAuthInstance();
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        console.log('[UserContext] User is authenticated:', firebaseUser.uid);
        setUser({
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName,
          photoURL: firebaseUser.photoURL,
          isGuest: false,
          isAuthenticated: true,
        });
      } else {
        console.log('[UserContext] No authenticated user');
        const guestUser = localStorage.getItem('guestUser');
        if (guestUser) {
          setUser(JSON.parse(guestUser));
        } else {
          setUser(null);
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [setLoading]);

  const login = useCallback(
    async (platform) => {
      setLoading(true);
      setError(null);
      try {
        if (platform === 'guest') {
          const guestUser = setUpGuestMode();
          setUser(guestUser);
          setGuestMode();
          console.log('[UserContext] Guest user created successfully');
        } else {
          // Redirect to your backend's OAuth initiation endpoint
          window.location.href = `${process.env.REACT_APP_BACKEND_URL}/oauth/${platform}`;
        }
      } catch (error) {
        console.error('[UserContext] Error during login:', error);
        setError('Login failed. Please try again.');
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [setGuestMode, setLoading]
  );

  const loginWithToken = useCallback(
    async (token) => {
      setLoading(true);
      setError(null);
      try {
        const auth = firebaseInstance.getAuthInstance();
        await signInWithCustomToken(auth, token);
        const firebaseUser = auth.currentUser;
        setUser({
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName,
          photoURL: firebaseUser.photoURL,
          isGuest: false,
          isAuthenticated: true,
        });
        console.log('[UserContext] User authenticated successfully with token');
        navigate('/play'); // Redirect to the desired route after login
      } catch (error) {
        console.error('[UserContext] Error during token login:', error);
        setError('Login failed. Please try again.');
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [navigate, setLoading]
  );

  const logout = useCallback(async () => {
    console.log('[UserContext] Attempting to log out user');
    setLoading(true);
    try {
      const auth = firebaseInstance.getAuthInstance();
      await signOut(auth);
      localStorage.removeItem('guestUser');
      setUser(null);
      console.log('[UserContext] User logged out successfully');
      navigate('/login');
    } catch (error) {
      console.error('[UserContext] Error during logout:', error);
      setError('Logout failed. Please try again.');
      throw error;
    } finally {
      setLoading(false);
    }
  }, [navigate, setLoading]);

  const contextValue = {
    user,
    setUser,
    login,
    loginWithToken,
    logout,
    error,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export { UserContext };

// src/App.jsx

import React, { useEffect, Suspense, lazy, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import '../styles/styles.css';
import '../styles/missions.css';
import LoadingScreen from './LoadingScreen.jsx';
import { useUser, UserProvider } from '../contexts/UserContext';
import { GuestProvider } from '../contexts/GuestContext';
import { useLoading, LoadingProvider } from '../contexts/LoadingContext';
import { GameStatsProvider } from '../contexts/GameStatsContext';
import firebaseInstance from './firebaseSingleton.jsx';
import ErrorBoundary from './ErrorBoundary.jsx';
import { setAuthToken, removeAuthToken } from '../services/api';

// Lazy load components for better performance
const LandingPage = lazy(() => import('../components/LandingPage.jsx'));
const LoginPage = lazy(() => import('../components/LoginPage.jsx'));
const MissionsPage = lazy(() => import('../components/MissionsPage.jsx'));
const ProfilePage = lazy(() => import('../components/ProfilePage.jsx'));
const SocialMediaConnect = lazy(() => import('../components/SocialMediaConnect.jsx'));
const OAuthCallback = lazy(() => import('../components/OAuthCallback.jsx'));

// Footer pages
const TermsPage = lazy(() => import('../components/TermsPage.jsx'));
const PrivacyPolicyPage = lazy(() => import('../components/PrivacyPolicyPage.jsx'));
const ContactPage = lazy(() => import('../components/ContactPage.jsx'));
const AboutPage = lazy(() => import('../components/AboutPage.jsx'));
const CareersPage = lazy(() => import('../components/CareersPage.jsx'));

// Dashboard and main sections
const Dashboard = lazy(() => import('../components/Dashboard.jsx'));
const FeaturesPage = lazy(() => import('../components/FeaturesPage.jsx'));
const IntegrationsPage = lazy(() => import('../components/IntegrationsPage.jsx'));
const TestimonialsPage = lazy(() => import('../components/TestimonialsPage.jsx'));
const PricingPage = lazy(() => import('../components/PricingPage.jsx'));
const FAQPage = lazy(() => import('../components/FAQPage.jsx'));

// Configuration object for API and services
const config = {
  apiUrl: import.meta.env.VITE_API_BASE_URL,
  authUrl: `${import.meta.env.VITE_API_BASE_URL}/auth`,
  githubAuthUrl: `https://github.com/login/oauth/authorize?client_id=${import.meta.env.VITE_GITHUB_CLIENT_ID}&scope=user`,
  twitterAuthUrl: `${import.meta.env.VITE_API_BASE_URL}/twitterCallback`,
  profileUrl: `${import.meta.env.VITE_API_BASE_URL}/profile`,
  missionsUrl: `${import.meta.env.VITE_API_BASE_URL}/missions`,
  socialMediaUrl: `${import.meta.env.VITE_API_BASE_URL}/socialMedia`,
  saveScoreUrl: `${import.meta.env.VITE_API_BASE_URL}/saveScore`,
  leaderboardUrl: `${import.meta.env.VITE_API_BASE_URL}/leaderboard`,
  referralUrl: `${import.meta.env.VITE_API_BASE_URL}/getReferralInfo`,
  telegramBotUsername: import.meta.env.VITE_TELEGRAM_BOT_USERNAME,
};

// Firebase configuration for authentication and database
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

// Timeout for loading state
const LOADING_TIMEOUT = 30000;

// Firebase Initialization Component
const FirebaseInitializer = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeFirebase = async () => {
      try {
        await firebaseInstance.initialize(firebaseConfig);
        setIsInitialized(true);
      } catch (err) {
        console.error('Failed to initialize Firebase:', err);
        setError(err.message);
      }
    };
    initializeFirebase();
  }, []);

  if (error) {
    return <div className="error-container">Error initializing app: {error}</div>;
  }

  if (!isInitialized) {
    return <LoadingScreen type="default" />;
  }

  return children;
};

// Main Application Component
const AppContent = () => {
  const { user, error: userError, logout } = useUser();
  const { loading, setLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();
  const [appError, setAppError] = useState(null);
  const [initializationComplete, setInitializationComplete] = useState(false);

  const initializeApp = useCallback(async () => {
    try {
      setLoading(true);
      const firebaseApp = await firebaseInstance.initialize(firebaseConfig);
      if (firebaseApp) {
        setInitializationComplete(true);
      } else {
        throw new Error('Failed to initialize Firebase');
      }
    } catch (err) {
      console.error('Error during app initialization:', err);
      setAppError(`Failed to initialize the app: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [setLoading]);

  useEffect(() => {
    if (!initializationComplete) {
      initializeApp();
    }
    const loadingTimeout = setTimeout(() => {
      if (!initializationComplete) {
        setLoading(false);
        setAppError('App initialization timed out. Please refresh the page.');
      }
    }, LOADING_TIMEOUT);

    return () => clearTimeout(loadingTimeout);
  }, [initializeApp, initializationComplete, setLoading]);

  useEffect(() => {
    const checkUserAndNavigate = () => {
      const publicRoutes = ['/', '/login', '/auth/callback'];
      if (!loading && initializationComplete && !publicRoutes.includes(location.pathname) && !user) {
        navigate('/login', { replace: true });
      }
    };
    checkUserAndNavigate();
  }, [loading, initializationComplete, navigate, location.pathname, user]);

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      removeAuthToken();
      navigate('/login');
    } catch (error) {
      setAppError('Failed to log out. Please try again.');
    }
  }, [logout, navigate]);

  if (loading || !initializationComplete) {
    return <LoadingScreen type="default" />;
  }

  if (appError || userError) {
    return (
      <div className="error-container">
        <h2>An error occurred</h2>
        <p>{appError || userError}</p>
        <button onClick={() => window.location.reload()} className="btn-refresh">
          Refresh Page
        </button>
      </div>
    );
  }

  return (
    <div className="app">
      <main id="main-content" role="main" className="flex-grow">
        <Suspense fallback={<LoadingScreen type="default" />}>
          <Routes>
            {/* Main and Auth Routes */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage config={config} />} />
            <Route path="/auth/callback" element={<OAuthCallback />} />
            <Route path="/missions" element={<MissionsPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/social/connect" element={<SocialMediaConnect />} />

            {/* Dashboard and Main Sections */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/features" element={<FeaturesPage />} />
            <Route path="/integrations" element={<IntegrationsPage />} />
            <Route path="/testimonials" element={<TestimonialsPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/faq" element={<FAQPage />} />

            {/* Footer and Legal Pages */}
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/careers" element={<CareersPage />} />

            {/* Fallback Route for 404 */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </main>
    </div>
  );
};

// Not Found Page Component
const NotFoundPage = () => (
  <div className="not-found-container">
    <h1>404 - Page Not Found</h1>
    <p>Oops! The page you are looking for does not exist.</p>
    <Button onClick={() => window.location.href = '/'} className="mt-4 bg-indigo-600 hover:bg-indigo-700 text-white">
      Go to Home
    </Button>
  </div>
);

// App Component Wrapper with Providers
const App = () => (
  <Router>
    <FirebaseInitializer>
      <ErrorBoundary>
        <GuestProvider>
          <LoadingProvider>
            <UserProvider>
              <GameStatsProvider>
                <AppContent />
              </GameStatsProvider>
            </UserProvider>
          </LoadingProvider>
        </GuestProvider>
      </ErrorBoundary>
    </FirebaseInitializer>
  </Router>
);

// DOM initialization
document.addEventListener('DOMContentLoaded', () => {
  const rootElement = document.getElementById('root');
  const root = createRoot(rootElement);
  root.render(<App />);
});

export default App;

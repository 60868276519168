// api.js

import axios from 'axios';
import { corsHandler } from '../config/corsConfig';

const API_BASE_URL = '/'; // Use relative paths since frontend and backend are on the same domain

export const SUPPORTED_PLATFORMS = ['twitter', 'github', 'telegram'];

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem('authToken', token);
    api.defaults.headers['Authorization'] = `Bearer ${token}`;
  }
};

export const removeAuthToken = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken');
  delete api.defaults.headers['Authorization'];
};

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const res = await corsHandler(() =>
          axios.post(`${API_BASE_URL}/refreshToken`, { refreshToken })
        );
        const { token } = res.data;

        setAuthToken(token);
        return api(originalRequest);
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        removeAuthToken();
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

// Updated handleApiError function to return a consistent error object
const handleApiError = (error) => {
  console.error('API Error:', error);
  if (error.response) {
    // Server responded with a status code outside the 2xx range
    console.error('Response data:', error.response.data);
    return { success: false, error: error.response.data.message || error.message };
  } else if (error.request) {
    // No response received from server
    console.error('No response received:', error.request);
    return { success: false, error: 'No response received from server' };
  } else {
    // Error setting up the request
    console.error('Error setting up request:', error.message);
    return { success: false, error: error.message };
  }
};

// API functions

export const socialAuth = async (platform, authData) => {
  try {
    const response = await corsHandler(() =>
      api.post('/socialAuthentication', { platform, authData })
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const getTwitterOAuthUrl = async (redirectUri) => {
  try {
    const response = await corsHandler(() =>
      api.post('/auth/oauth/initiate', { platform: 'twitter', redirectUri })
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const getUserProfile = async () => {
  try {
    const response = await corsHandler(() => api.get('/getUserProfile'));
    if (response && response.data) {
      return { success: true, data: response.data };
    } else {
      console.error('No response data from /getUserProfile');
      return { success: false, error: 'No response data from server' };
    }
  } catch (error) {
    return handleApiError(error);
  }
};

// Add this function to provide the missing `fetchUserProfileData`
export const fetchUserProfileData = async () => {
  try {
    const response = await corsHandler(() => api.get('/userProfile'));
    if (response && response.data) {
      return { success: true, data: response.data };
    } else {
      return { success: false, error: 'No user profile data available' };
    }
  } catch (error) {
    return handleApiError(error);
  }
};

export const updateUserProfile = async (profileData) => {
  try {
    const response = await corsHandler(() =>
      api.put('/updateUserProfile', profileData)
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const getSocialMediaStats = async () => {
  try {
    const response = await corsHandler(() => api.get('/getSocialMediaStats'));
    if (response && response.data) {
      return { success: true, data: response.data };
    } else {
      console.error('No response data from /getSocialMediaStats');
      return { success: false, error: 'No response data from server' };
    }
  } catch (error) {
    return handleApiError(error);
  }
};

export const linkSocialMediaAccount = async (platform, token) => {
  try {
    const response = await corsHandler(() =>
      api.post('/linkSocialMedia', { platform, token })
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const unlinkSocialMediaAccount = async (platform) => {
  try {
    const response = await corsHandler(() =>
      api.post('/unlinkSocialMedia', { platform })
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const getMissions = async () => {
  try {
    const response = await corsHandler(() => api.get('/missions/all'));
    if (response && response.data) {
      return { success: true, data: response.data };
    } else {
      console.error('No response data from /missions/all');
      return { success: false, error: 'No response data from server' };
    }
  } catch (error) {
    return handleApiError(error);
  }
};

export const completeMission = async (missionId) => {
  try {
    const response = await corsHandler(() =>
      api.post('/completeMission', { missionId })
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const getLeaderboard = async () => {
  try {
    const response = await corsHandler(() => api.get('/leaderboard'));
    if (response && response.data) {
      return { success: true, data: response.data };
    } else {
      console.error('No response data from /leaderboard');
      return { success: false, error: 'No response data from server' };
    }
  } catch (error) {
    return handleApiError(error);
  }
};

export const saveScore = async (scoreData) => {
  try {
    const response = await corsHandler(() => api.post('/saveScore', scoreData));
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const initiateOAuth = async (platform, oauthData = null) => {
  try {
    let response;
    if (oauthData) {
      response = await corsHandler(() =>
        api.post(`/auth/oauth/callback`, { platform, ...oauthData })
      );
    } else {
      response = await corsHandler(() =>
        api.post('/auth/oauth/initiate', { platform })
      );
    }

    if (response.data) {
      if (typeof response.data === 'string') {
        return { success: true, data: response.data };
      } else if (response.data.authUrl || response.data.url) {
        return {
          success: true,
          data: response.data.authUrl || response.data.url,
        };
      } else if (response.data.firebaseToken) {
        return {
          success: true,
          data: { firebaseToken: response.data.firebaseToken },
        };
      }
    }

    console.error('Unexpected response format:', response.data);
    return {
      success: false,
      error: 'Invalid response format from server',
    };
  } catch (error) {
    console.error(`Error in OAuth process for ${platform}:`, error);
    return {
      success: false,
      error: error.message || 'An unknown error occurred',
    };
  }
};

export const handleOAuthCallback = async (platform, code, state) => {
  try {
    const response = await corsHandler(() =>
      api.post(`/auth/oauth/callback`, { platform, code, state })
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const logout = async () => {
  try {
    const response = await corsHandler(() => api.post('/logout'));
    removeAuthToken();
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const addWallet = async (walletData) => {
  try {
    const response = await corsHandler(() =>
      api.post('/addWallet', walletData)
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const removeWallet = async (walletId) => {
  try {
    const response = await corsHandler(() =>
      api.post('/removeWallet', { walletId })
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const getReferralInfo = async () => {
  try {
    const response = await corsHandler(() => api.get('/getReferralInfo'));
    if (response && response.data) {
      return { success: true, data: response.data };
    } else {
      console.error('No response data from /getReferralInfo');
      return { success: false, error: 'No response data from server' };
    }
  } catch (error) {
    return handleApiError(error);
  }
};

export const getProtectedData = async () => {
  try {
    const response = await corsHandler(() => api.get('/protectedRoute'));
    if (response && response.data) {
      return { success: true, data: response.data };
    } else {
      console.error('No response data from /protectedRoute');
      return { success: false, error: 'No response data from server' };
    }
  } catch (error) {
    return handleApiError(error);
  }
};

// New function to fetch demo profile data for users not logged in
export const fetchDemoProfileData = async () => {
  try {
    // Mock data for demo purposes
    const demoData = {
      username: 'DemoUser',
      reps: 150,
      workouts: 10,
      progress: {
        arms: 80,
        legs: 60,
        core: 90,
      },
    };
    return { success: true, data: demoData };
  } catch (error) {
    return { success: false, error: 'Error fetching demo profile data' };
  }
};

export default api;

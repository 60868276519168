import firebaseInstance from './firebaseSingleton.jsx';
import {
  signInWithCustomToken,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';

const GUEST_USER_KEY = 'guestUser';

// Authenticate with Firebase
export async function authenticateWithFirebase(firebaseToken) {
  console.log('[Auth] Attempting to authenticate with Firebase...');
  try {
    const auth = firebaseInstance.getAuthInstance();
    await setPersistence(auth, browserLocalPersistence);

    const userCredential = await signInWithCustomToken(auth, firebaseToken);
    console.log('[Auth] User authenticated successfully with Firebase:', userCredential.user.uid);

    return userCredential.user;
  } catch (error) {
    console.error('[Auth] Error authenticating with Firebase:', error);

    if (error.code === 'auth/invalid-custom-token' || error.code === 'auth/token-expired') {
      console.log('[Auth] Token expired or invalid');
      throw new Error('TOKEN_EXPIRED');
    }

    throw new Error('Firebase authentication failed: ' + error.message);
  }
}

// Set up guest mode
export function setUpGuestMode() {
  console.log('[Auth] Setting up guest mode...');
  const guestUser = {
    uid: 'guest-' + Date.now(),
    isGuest: true,
    isAuthenticated: true,
  };
  localStorage.setItem(GUEST_USER_KEY, JSON.stringify(guestUser));
  return guestUser;
}

// Sign out user
export async function signOutUser() {
  console.log('[Auth] Attempting to sign out user...');
  try {
    const auth = firebaseInstance.getAuthInstance();
    await signOut(auth);
    localStorage.removeItem(GUEST_USER_KEY);
    console.log('[Auth] User signed out successfully');
  } catch (error) {
    console.error('[Auth] Error signing out:', error);
    throw new Error('Failed to sign out: ' + error.message);
  }
}

// Refresh auth token
export async function refreshAuthToken() {
  console.log('[Auth] Attempting to refresh auth token...');
  try {
    const auth = firebaseInstance.getAuthInstance();
    const currentUser = auth.currentUser;
    if (currentUser) {
      const newToken = await currentUser.getIdToken(true);
      console.log('[Auth] Auth token refreshed successfully');
      return newToken;
    } else {
      throw new Error('No authenticated user');
    }
  } catch (error) {
    console.error('[Auth] Error refreshing auth token:', error);
    throw new Error('Failed to refresh auth token: ' + error.message);
  }
}

// Handle Firebase Auth state change
export function onAuthStateChange(callback) {
  const auth = firebaseInstance.getAuthInstance();
  return onAuthStateChanged(auth, (user) => {
    if (user) {
      console.log('[Auth] Auth state changed: User is signed in', user.uid);
      callback(user);
    } else {
      console.log('[Auth] Auth state changed: User is signed out');
      const guestUser = localStorage.getItem(GUEST_USER_KEY);
      if (guestUser) {
        console.log('[Auth] Guest user detected');
        callback(JSON.parse(guestUser));
      } else {
        callback(null);
      }
    }
  });
}

// Check if current user is a guest
export function isGuestUser() {
  const guestUser = localStorage.getItem(GUEST_USER_KEY);
  return !!guestUser;
}
